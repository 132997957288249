import React from "react";
import Button from "@mui/material/Button";
import mucaro from "../images/mucaro.png";
import kk from "../images/kk.png";
import muvn from "../images/muvn_logo.png";
import "../App.css";

const Projects = () => {
  return (
    <div className="projects">
      <div className="projectsContainer">
        <h3 style={{ color: "#04CCFBFF" }}>Projects in development</h3>

        <Button
          href="https://www.muvnday.com"
          style={{
            height: "100%",
            width: "50vw",
            backgroundColor: "black",
            borderRadius: "10px",
          }}
        >
          <img className="muvn" src={muvn} alt="muvnLogo" />
        </Button>

        <Button
          href="https://www.mucarobakingco.com"
          style={{
            height: "100%",
            width: "50vw",
            backgroundColor: "black",
            borderRadius: "10px",
          }}
        >
          <img className="mucaro" src={mucaro} alt="mucaroLogo" />
        </Button>

        <Button
          href="https://www.kweensklub.com"
          style={{
            height: "100%",
            width: "50vw",
            backgroundColor: "black",
            borderRadius: "10px",
          }}
        >
          <img className="kk" src={kk} alt="kkLogo" />
        </Button>
        {/* <h3 style={{ color: "#04CCFBFF" }}>More coming soon</h3> */}
      </div>
    </div>
  );
};

export default Projects;
