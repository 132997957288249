import React from "react";
import "../App.css";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import urielLogo from "../images/urielLogo.png";
// import TextField from "@mui/material/TextField";
import "../App.css";
import SendIcon from "@mui/icons-material/Send";

import { API } from "aws-amplify";
import { createCandidate } from "../graphql/mutations";

// function Contact() {
//   const [formState, setFormState] = React.useState({
//     name: "",
//     email: "",
//     message: "",
//   });

//   const onSubmit = async (e) => {
//     e.preventDefault();
//     // e.target.reset();

//     const { name, email, message } = formState;
//     if (name && email && message) {
//       try {
//         await API.graphql({
//           query: createCandidate,
//           variables: {
//             input: {
//               name,
//               email,
//               message,
//             },
//           },
//         }).then(alert("Your message has been submitted. Thank you!"));
//       } catch (err) {
//         alert(err, "An error has ocurred.");
//       }
//     } else {
//       console.log("not sure whats happening");
//     }
//     setFormState({
//       name: "",
//       email: "",
//       message: "",
//     });
//   };

//   return (
//     <div className="Contact">
//       <div className="contactContainer">
//         <form onSubmit={onSubmit}>
//           <div>
//             {" "}
//             <label>
//               <p>Name</p>
//             </label>
//             <input
//               type="text"
//               // id="name"
//               placeholder="Your Full Name"
//               value={formState.name || []}
//               onChange={(e) =>
//                 setFormState({ ...formState, name: e.target.value })
//               }
//               required
//             />
//           </div>

//           <div>
//             <label>
//               <p>Email</p>
//             </label>
//             <input
//               type="email"
//               // id="email"
//               placeholder="Your Email"
//               value={formState.email}
//               onChange={(e) =>
//                 setFormState({ ...formState, email: e.target.value })
//               }
//               required
//             />
//           </div>

//           <div>
//             {" "}
//             <label>
//               <p>Message</p>
//             </label>
//             <textarea
//               name="message"
//               value={formState.message}
//               className="message"
//               placeholder="Your Message"
//               // value={formState.message}
//               onChange={(e) =>
//                 setFormState({ ...formState, message: e.target.value })
//               }
//               required
//               // rows={30}
//               minLength={20}
//               maxLength={1000}
//             >
//               {formState.message}
//             </textarea>
//           </div>

//           <button className="send" type="submit">
//             <SendIcon />
//           </button>
//         </form>
//       </div>
//     </div>
//   );
// }

// export default Contact;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  bgcolor: "#04CCFBFF ",
  border: "none",
  boxShadow: "none",
  outline: "none",
  p: 4,
  color: "black",
  textAlign: "center",
  paddingTop: "15px",
};

const Contact = () => {
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleOpen2 = () => setOpen2(true);

  const handleClose = () => setOpen(false);
  const handleClose2 = () => setOpen2(false);

  return (
    <div className="Contact">
      <div className="contactContainer">
        <br />
        <Button href="https://github.com/Ugonz86">
          {" "}
          <GitHubIcon fontSize="large" className="icons" />{" "}
        </Button>
        <Button href="https://www.linkedin.com/in/urielgr/">
          <LinkedInIcon fontSize="large" className="icons" />{" "}
        </Button>
        <Button href="" onClick={handleOpen}>
          <EmailIcon
            fontSize="large"
            style={{ color: "whitesmoke", margin: "0" }}
          />
        </Button>
        <Button href="" onClick={handleOpen2}>
          <img
            style={{ height: "4vh", objectFit: "contain" }}
            src={urielLogo}
            alt="self"
          />
        </Button>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Contact Me: ugonzalez86@gmail.com
          </Typography>
        </Box>
      </Modal>

      <Modal
        open={open2}
        onClose={handleClose2}
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            UGX Design
            <br />
            Under Development
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default Contact;
