import balcony from "../images/balcony.jpg";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import "../App.css";

const Landing = () => {
  return (
    <div className="landing">
      <div className="landingContainer">
        <img className="portrait" src={balcony} alt="portrait"></img>
        <p className="text">
          I'm{" "}
          <span style={{ color: "#04CCFBFF", fontWeight: "bolder" }}>
            Uriel
          </span>{" "}
          <i style={{ fontWeight: "lighter" }}>[oo-rree-él]</i>, a Software
          Developer with 2+ years of professional experience, located in
          Seattle, Washington.
          <br />
          <br />I currently specialize in front-end development. <br />
        </p>

        <a className="pdfButton" href="resume.pdf" download="resume.pdf">
          {" "}
          <p>Download Resume</p>
          <FileDownloadIcon style={{ fontSize: "25px", marginLeft: "5px" }} />
        </a>
      </div>
    </div>
  );
};

export default Landing;
