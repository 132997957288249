import React, { useState, useEffect } from "react";
// import SwipeUpIcon from '@mui/icons-material/SwipeUp';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const ScrollToTop = () => {
  const [showScrollTopButton, setShowScrollTopButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 300) {
        setShowScrollTopButton(true);
      } else {
        setShowScrollTopButton(false);
      }
    });
  }, []);

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      {showScrollTopButton && (
        <button className="top-btn-position top-btn-style" onClick={scrollTop}>
          <ArrowUpwardIcon />
        </button>
      )}
    </div>
  );
};

export default ScrollToTop;
