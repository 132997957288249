import React, { useRef } from "react";
import "./App.css";
import ScrollToTop from "./components/ScrollToTop";
import Landing from "./components/Landing";
import Services from "./components/Services";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
// import Resume from "./components/Resume";
// import { style } from "@mui/system";

import Amplify from "@aws-amplify/core";
import config from "../src/aws-exports";
Amplify.configure(config);

function App() {
  // const [isActive, setIsActive] = useState(false);
  const services = useRef(null);
  const projects = useRef(null);
  const contact = useRef(null);

  //   const updateMedia = () => {
  //     setDesktop(window.innerWidth > 650);
  //   };

  //   useEffect(() => {
  //     window.addEventListener("resize", updateMedia);
  //     return () => window.removeEventListener("resize", updateMedia);
  //   });

  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  return (
    <div className="App">
      <div className="header">
        <p
          onClick={() => scrollToSection(services)}
          className="link"
          id="serviceslink"
        >
          SKILLSET
        </p>
        <p
          onClick={() => scrollToSection(projects)}
          className="link"
          id="projectslink"
        >
          PROJECTS
        </p>
        <p
          onClick={() => scrollToSection(contact)}
          className="link"
          id="contactlink"
        >
          CONTACT
        </p>
      </div>

      <div className="landing">
        <Landing />
      </div>

      <div ref={services} className="services">
        <Services />
        <ScrollToTop />
      </div>

      <div ref={projects} className="projects">
        <Projects />
        <ScrollToTop />
      </div>
      <div ref={contact} className="contact">
        <Contact />
        <ScrollToTop />
      </div>
      <Footer />
    </div>
  );
}

export default App;
