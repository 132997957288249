import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      I built this portfolio with JavaScript and React, styled with CSS and cloud hosted with AWS Amplify. A work
      in progress.
    </div>
  );
};

export default Footer;
