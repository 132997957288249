import React, { useState } from "react";
import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "../App.css";

const style = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  bgcolor: "#04CCFBFF",
  p: 4,
  border: "none",
  outline: "none",
  boxShadow: "none",
  textAlign: "center",
  paddingTop: "15px",
  width: "70vw",
  justifyContent: "center",
  alignItems: "center",
  margin: "auto",
};

const Services = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="services">
      <div className="servicesContainer">
        <h3 className="box">JavaScript</h3>
        <h3 className="box">Node JS</h3>
        <h3 className="box">React</h3>
        <h3 className="box">HTML/CSS</h3>
        <h3 className="box">Agile Methodology</h3>
        <h3 className="box">Responsive Design</h3>
        <h3 className="box">UX</h3>
        <h3 onClick={handleOpen} className="box1">
          AWS
        </h3>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            style={{ color: "#141414FF" }}
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            Amplify - SES - Lambda - DynamoDB - S3 - VPC - Cognito
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default Services;
